<template>
    <div
        class="flex items-start gap-2.5 pl-1.5 w-full"
        data-testid="comment-response"
    >
        <IconArrayReturnRight class="text-gray-disabled w-5 h-5 mt-1" />
        <FeedbackCard>
            <template #title>
                <div class="flex flex-col gap-1 w-full">
                    <span
                        class="text-gray-800 font-medium text-fifteen-exploitation leading-4 inline-flex items-center"
                    >
                        <span>{{ commentAuthorName }}</span>
                        <IconBadgeCheck
                            v-if="isSecondaryResponse || !authorName"
                            class="text-blue-500 mx-1 w-3 h-3"
                            :class="[
                                isSecondaryResponse
                                    ? 'text-violet-600'
                                    : 'text-blue-500'
                            ]"
                            is-solid
                        />
                        <IconChargemap
                            v-else
                            class="text-light-blue-primary-medium mx-1 w-3 h-3"
                            is-solid
                        />
                        <span class="text-gray-500 font-normal">
                            {{ $t('has_answered') }} {{ displayDate }}
                        </span>
                    </span>
                    <div
                        class="flex flex-col rounded gap-2 p-2 pb-3"
                        :class="[
                            isSecondaryResponse ? 'bg-violet-50' : 'bg-sky-50'
                        ]"
                    >
                        <span
                            ref="response-content"
                            class="text-fifteen-exploitation font-normal leading-6 tracking-light break-words"
                            :class="[
                                {
                                    'max-h-[30px] overflow-hidden':
                                        !displayEverything
                                },
                                isSecondaryResponse
                                    ? 'text-violet-600'
                                    : 'text-sky-700'
                            ]"
                        >
                            {{ content }}
                        </span>
                        <a
                            v-if="!displayEverything"
                            class="text-sky-600 font-medium text-thirteen-exploitation tracking-light cursor-pointer"
                            @click="onClickUnfold"
                        >
                            {{ $t('show.more') }}
                        </a>
                        <a
                            v-if="hasClickedToUnfold"
                            class="text-sky-600 font-medium text-thirteen-exploitation tracking-light cursor-pointer"
                            @click="onClickFold"
                        >
                            {{ $t('show.less') }}
                        </a>
                    </div>
                </div>
            </template>
        </FeedbackCard>
    </div>
</template>

<script>
import { DateTime } from "luxon";
import IconArrayReturnRight from "../../../icons/IconArrayReturnRight.vue";
import IconBadgeCheck from "../../../icons/IconBadgeCheck.vue";
import IconChargemap from "../../../icons/IconChargemap.vue";
import FeedbackCard from "../FeedbackCard.vue";
const FIVE_LINES_HEIGHT = 120;
export default {
  name: "CommentResponse",
  components: {
    FeedbackCard,
    IconArrayReturnRight,
    IconBadgeCheck,
    IconChargemap
  },
  props: {
    content: {
      type: String,
      required: true
    },
    authorName: {
      type: String,
      default: ""
    },
    creationDate: {
      type: Object,
      required: true
    },
    mustDisplayWholeComment: {
      type: Boolean,
      required: true
    },
    locale: {
      type: String,
      required: true
    },
    isSecondaryResponse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hasClickedToUnfold: false,
      hasSmallContentHeight: false
    };
  },
  computed: {
    commentAuthorName() {
      return this.authorName || "Chargemap";
    },
    displayEverything() {
      return this.hasSmallContentHeight || this.mustDisplayWholeComment || this.hasClickedToUnfold;
    },
    displayDate() {
      const now = DateTime.now();
      if (now.diff(this.creationDate, "hours").hours >= 24) {
        return this.creationDate.toRelativeCalendar({
          locale: this.locale
        });
      }
      return this.creationDate.toRelative({ locale: this.locale });
    }
  },
  mounted() {
    this.hasSmallContentHeight = this.$refs["response-content"]?.clientHeight < FIVE_LINES_HEIGHT;
  },
  methods: {
    onClickUnfold() {
      this.hasClickedToUnfold = true;
    },
    onClickFold() {
      this.hasClickedToUnfold = false;
    }
  }
};
</script>
